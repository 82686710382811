import { post } from "@/utils/http"

// 拦截器，登录检查，不同设备跳转
export default function (router) {
  router.beforeEach((to, from, next) => {
    if (to.meta.title) { //如果设置标题，拦截后设置标题
      document.title = to.meta.title
    }

    // 参数中是否包含companyId，若有，跳转到对应companyId的登录页
    let projectId = to.params.id
    let nextUrl = to.query.nextUrl
    // 登录地址
    let jumpUrl = '/login/#/login' + '?nextUrl=' + encodeURIComponent(window.location.href)
    // 如果不需要登录校验 // ignoreLoginCheck 写在当前router的meta中（信息元）
    if (to.matched.some(record => record.meta.ignoreLoginCheck)) {
      next()
    }else {
      post("/user/login_check", projectId ? {
        id: projectId
      } : {}).then((data) => {
        if (data.success) {
          if(to.matched.some(record => record.meta.title==="登录")) {
            if(nextUrl) {
              window.open(nextUrl, '_self'); 
            }else {
              window.open('/project/#/', '_self'); 
            }
          }else {
            router.app.$store.commit('setUserInfo')
            next()
          }
        } else {
          if(to.matched.some(record => record.meta.title==="登录")) {
            next()
          }else if(data.code == 33006) {
            window.open(jumpUrl, '_self'); 
          }
        }
      }).catch((err) => {
        console.log(err)
        // next()
      })
    }
  });
}
