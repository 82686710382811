import Vue from 'vue'
import Vuex from 'vuex'
import url from './url.project';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 用户信息
    userInfo: {
      userId: "",
      email: "",
      username: "",
      token: ""
    }
  },
  getters: {
    userInfo(state) {
      return state.userInfo
    },
    staticURL(){
      return url.staticURL
    }
  },
  mutations: {
    setToken(state, value) {
      state.token = value
    },
    setUserInfo(state, params) {
      state.userInfo.userId = KSX.getCookie("userId")
      state.userInfo.token = KSX.getCookie("token")
      // state.userInfo.username = params.username
    }
  },
  actions: {

  }
})
