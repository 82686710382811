import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import zh from './zh'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh', // 设置语言环境
  fallbackLocale: localStorage.getItem('lang') || 'zh',
  messages: {
    en,
    zh
  } // 设置语言环境信息
})

const loadedLanguages = ['en', 'zh'] // 我们的预装默认语言

export function setI18nLanguage (lang) {
  i18n.locale = lang
  localStorage.setItem('lang',lang)

  const langMap={
    zh:'zh',
    en:'en'
  }
  document.querySelector('html').setAttribute('lang', lang)

  return lang
}

export function i18nText(val) {
  return i18n.t(val)
}