import Vue from 'vue'
import VueRouter from 'vue-router'
import interceptor from '@/router/interceptor'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: () => import('../components/Login.vue'),
    meta: {
      title: "登录"
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/Login.vue'),
    meta: {
      title: "登录"
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../components/Register.vue'),
    meta: {
      title: "注册",
      ignoreLoginCheck: true
    }
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('../components/ForgetPwd.vue'),
    meta: {
      title: "忘记密码",
      ignoreLoginCheck: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../components/Home.vue'),
    meta: {
      title: "服务创新工具",
      ignoreLoginCheck: true
    }
  }
  // {
  //   path: '*',
  //   component: () => import('../Entrance.vue'),
  //   meta: {
  //     title: "服务创新工具"
  //   }
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

interceptor(router)


export default router 
