export default {
  "未命名项目": "Untitled",
  "使命分析": "Mission analysis",
  "问题建模": "Problem modeling",
  "需求定义": "Requirements definition",
  "问题分析": "Problem analysis",
  "验证评价": "Verification evaluation",
  "方案优化": "Scheme optimization",
  "创新启发": "Innovation inspiration",
  "制作服务画布": "Service Canvas",
  "绘制服务蓝图": "Service Blueprint",
  "绘制共情图": "Empathy Map",
  "制作QFD": "QFD",
  "Harris Profile": "Harris Profile",
  "上传文件": "Upload",
  "服务蓝图": "Service blueprint",
  "共情图": "Empathy map",
  "工具栏": "Tools",
  "添加分隔符": "Add Split line",
  "基本图形": "Basic Graphic",
  "标识符": "Icon",
  "便签": "Sticky Notes",
  "时间": "TIME",
  "物理证据": "EVIDENCE",
  "用户行为": "CUSTOER ACTIONS",
  "前台活动": "FRONTSTAGE ACTIONS",
  "后台活动": "BACKSTAGE ACTIONS",
  "支持流程": "SUPPORT PROCESS",
  "机会描述": "OPPORTUNITIES",
  "隐藏标识符": "Hide Icon",
  "导出": "Export",
  "生成DSM": "DSM",
  "画布复位": "Reset Canvas",
  "外部交互线": "LINE OF INTERACTION",
  "内部交互线": "LINE OF INTERNAL INTERACTION",
  "可见线": "LINE OF VISIBILITY",
  "痛点": "PAIN",
  "收获": "GAIN",
  "需要": "GAIN",
  "所思所想": "THINK AND FEEL",
  "所闻": "HEAR",
  "所见": "SEE",
  "所说所做": "SAY AND DO",
  "用户最大的困扰、阻碍、担心": "fears, frustrations, obstacles",
  "用户真正想要/需要的东西": "wants, needs, measure of success",
  "服务成功的标准": "",
  "生成用户之声": "Voice of Customer",
  "查看用户之声": "Voice of Customer",
  "隐藏用户之声": "hide VoC",
  "用户之声": "Voice of Customer",
  "权重": "Weight",
  "当前在线": "online now",
  "你": "you",
  "编辑节点": "edit",
  "字体大小": "font size",
  "字体颜色": "font color",
  "节点背景": "background",
  "边框颜色": "border color",
  "编辑连线": "connection line",
  "标签内容": "content",
  "线条样式": "line type",
  "线条宽度": "line width",
  "流动线条": "line fluid",
  "调整线条": "adjust line",
  "背景颜色": "background",
  "描边颜色": "border color",
  "10%": "10%",
  "50%": "50%",
  "100%": "100%",
  "150%": "150%",
  "200%": "200%",
  "适应屏幕": "fit to screen",
  "删除此节点": "Delete Node",
  "创建项目失败，请稍后刷新页面重试": "failed to create, try again later",
  "请求项目数据失败，请稍后刷新页面重试": "failed to request data, try again later",
  "项目名称限制30个字符，1个汉字占两个字符":  "up to 30 characters in length",
  "用户名称限制12个字符，1个汉字占两个字符":  "up to 12 characters in length",
  "用户名称保存成功": "Success！",
  "项目名称保存成功": "Success！",
  "保存成功": "Success！",
  "内容不为空不可删除，请清空泳道后再删除": "You are only allowed to delete empty swim-lane!",
  "只能输入0-10的数字": "only number, up to 10",
  "复制成功": "Copy succeeded",
  "请先添加痛点和需要": "PAIN and GAIN cannot be empty",
  "点击进行编辑": "click to edit",
  "导出共情图为图片": "export empathy map as image",
  "导出的图片中包含用户之声": "contain the Voice of Customer",
  "确定": "Confirm",
  "请先去生成用户之声": "please click “Voice of Customer” before export",
  "邮箱": "Email",
  "密码": "Password",
  "注册": "I don't have an account",
  "忘记密码": "Can't sign in",
  "登录": "Login",
  "注册账号": "Sign Up",
  "名字/昵称": "Username",
  "确认": "Continue",
  "返回登录": "Login",
  "输入6位的邮箱验证码": "6 digital code",
  "已发送验证码到": "confirmation code has been send ",
  "，请登录邮箱查看": "",
  "邮箱验证": "Check Email",
  "注册成功": "Sigh Up Succesfully",
  "去登录": "Login",
  "不小于8位的字母数字组合": "must contain at least 8 characters",
  "密码必须是不少于8位的字母数字组合": "Password must contain at least 8 characters",
  "邮箱格式不正确": "Enter a valid email address",
  "邮箱已存在": "You had already Signed up",
  "邮箱未注册": "Could not find your account, please check your email",
  "两次输入的密码不一致": "Enter the same password",
  "新密码": "enter password",
  "再次输入": "enter password again",
  "密码已重置": "password reset",
  "重置密码": "reset password",
  "验证码输入有误": "code error",
  "验证码错误": "code error",
  "名字或昵称仅中文、字母和数字，长度不超过20个字符": "Username can not be more than 20 characters",
  "返回": "Back",
  "您不是该项目的成员，没有访问权限": "Only the member of this project CAN access.",
  "当前项目": "Projects",
  "加入项目": "Join in",
  "创建项目": "Create",
  "快捷入口": "quick access",
  "邀请成员": "invite members",
  "最近更新": "modified",
  "分享邀请码，用户加入项目-输入邀请码即可": "Share the invitation code, users can join the project - enter the invitation code",
  "复制邀请码": "invitation code",
  "复制链接": "invitation link",
  "邀请码已复制": "code copied",
  "邀请链接已复制": "link copied",
  "输入邀请码加入项目": "Enter the invitation code to join the project",
  "请输入邀请码": "invitation code",
  "分享邀请链接，访问链接即可加入": "Share the invitation link, click the link to join",
  "邀请码不存在": "code error",
  "取消": "Cancel",
  "加入项目成功": "Success！",
  "后自动进入项目1": "Project page will be opened ",
  "后自动进入项目": " later",
  "成员管理": "Member Manage",
  "当前项目成员": "Members",
  "移交组长权限": "change leader",
  "成员": "Member",
  "项目权限": "Authority",
  "组长": "Leader",
  "全部权限": "all",
  "查看、小组讨论": "view, dicussion",
  "删除项目": "delete",
  "服务画布": "service canvas",
  "邀请成员加入": "invite members",
  "项目名称": "project name",
  "确认创建": "Create",
  "暂不进入": "Later",
  "进入项目": "Continue",
  "项目删除成功": "Success！",
  "分钟前": "minutes ago",
  "小时前": "hours ago",
  "天前": "days ago",
  "项目ID": "ProjectID",
  "已设置为组长": "Now, the leader is",
  "为组长": "",
  "组长权限已移交，你已无该页面访问权限": "Authority has changed, you can not access.",
  "成员删除成功": "Success！",
  "组长不可删除": "Not allowed to delete leader",
  "现在请每位同学独立思考，完成共情图，稍后将开始小组讨论。": "Please think independently and complete the empathy map. Group discussion will begin later.",
  "现在请每位同学独立思考，完成服务画布，稍后将开始小组讨论。": "Please think independently and complete the service canvas. Group discussion will begin later.",
  "现在开始小组讨论，每个组员都可以看到其它同学的想法啦～": "Now start the group discussion. Each group member can see the ideas of other students~",
  "确定删除该项目？": "Delete this Project?",
  "确定删除该成员？": "Delete this member?",
  "数据请求失败，请重试": "The request failed, please try again later",
  "你已经加入该项目": "You are already the member of this project",
  "赛博物理系统设计": "Service Innovation Platform",
  "你好！": "",
  "欢迎登录赛博物理系统设计": "Welcome to Service Innovation Platform",
  "请各位同学独立思考完成服务画布，稍后进行小组讨论": "Thinking",
  "请各位同学独立思考完成共情图，稍后进行小组讨论": "Thinking",
  "小组讨论": "Discussion",
  "确认终版服务画布": "Finished",
  "终版已确认": "Final version confirmed",
  "修改终版": "Edit",
  "组员独立思考": "Thinking",
  "现在开始小组讨论": "Discussing",
  "进入小组讨论": "Discussing",
  "确认终版": "Submit",
  "独立思考": "Thinking",
  "提交": "Submit",
  "多人小组共创画布": "Team work",
  "仅组长有控制权限": "Only team leader control it",
  "组员独立思考2": "Thinking by yourself",
  "小组讨论2": "Discussion",
  "确认终版共情图": "Confirm final Empathy map",
  "确认终版服务画布": "Confirm final Service Canvas",
  "进入小组讨论2": "Discussion",
  "退出当前账号": "Logout",
  "当前还没有项目，请点击右上角按钮去创建项目吧": 'No Project yet, click "Creat" to quick start',
  "注册失败": "failed to register, try again later",
  "账号密码不匹配": "please check your account or password",
  "暂未开放": "Coming",
  "更新数据": "update",
  "更新后，当前表格内的权重将被清空，用户之声显示当前画布上的最新数据，确认继续吗？": "Update will clear table weights. User Feedback shows latest canvas data. Continue?"

}