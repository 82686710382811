//封装axios请求
import axios from 'axios'

const STATIC_URL = () => {
    if(process.env.NODE_ENV == "development") {
        return "https://test1.thucps.com";
    }else {
        return "https://workflow.thucps.com"
    }
}

export const staticURL = STATIC_URL()
const headerParams = {}


if(KSX.getCookie("userId") && KSX.getCookie("token")) {
    headerParams.userId = KSX.getCookie("userId")
    headerParams.token = KSX.getCookie("token")
}
const instance = axios.create({
    timeout: 16000,
    baseURL: process.env.NODE_ENV == 'development' ? "/api" : "",
    // baseURL:  "/api" ,
    headers: {
        "Content-Type": "application/json; charset=UTF-8;",
        ...headerParams
    },
    withCredentials: true,
})

instance.interceptors.response.use(
    res => {
        if(res.config.url!='/user/login_check' && res.data.code == 33006) {
            window.open('/login/#/login' + '?nextUrl=' + encodeURIComponent(window.location.href), '_self');
        }
        return res.data
    },
    err => Promise.reject(err)
)

export const get = (url, data, config = {}) => instance.get(url, data, config)
export const post = (url, data, config = {}) => instance.post(url, data, config)
export const dlt = (url, data, config = {}) => instance.delete(url, data, config)
