// 域名
const domain = process.env.DOMAIN;
// 项目环境
const projectEnv = process.env.PROJECT_ENV;
// 打包环境
const nodeENV = process.env.NODE_ENV;
// 端口号
const port = window.location.port;


var staticURL
console.log(nodeENV, 'nodeENV');
if(nodeENV == "development") {
  staticURL = "development"
} else {
  staticURL = "prod"
}

export default {
  // 域名
  staticURL
}