export default {
  "未命名项目": "未命名项目",
  "使命分析": "使命分析",
  "问题建模": "问题建模",
  "需求定义": "需求定义",
  "问题分析": "问题分析",
  "验证评价": "验证评价",
  "方案优化": "方案优化",
  "创新启发": "创新启发",
  "制作服务画布": "制作服务画布",
  "绘制服务蓝图": "绘制服务蓝图",
  "绘制共情图": "绘制共情图",
  "制作QFD": "制作QFD",
  "Harris Profile": "Harris Profile",
  "上传文件": "上传文件",
  "服务蓝图": "服务蓝图",
  "共情图": "共情图",
  "工具栏": "工具栏",
  "添加分隔符": "添加分隔符",
  "基本图形": "基本图形",
  "标识符": "标识符",
  "便签": "便签",
  "时间": "时间",
  "物理证据": "物理证据",
  "用户行为": "用户行为",
  "前台活动": "前台活动",
  "后台活动": "后台活动",
  "支持流程": "支持流程",
  "机会描述": "机会描述",
  "隐藏标识符": "隐藏标识符",
  "导出": "导出",
  "生成DSM": "生成DSM",
  "画布复位": "画布复位",
  "外部交互线": "外部交互线",
  "内部交互线": "内部交互线",
  "可见线": "可见线",
  "痛点": "痛点",
  "收获": "收获",
  "需要": "需要",
  "所思所想": "所思所想",
  "所闻": "所闻",
  "所见": "所见",
  "所说所做": "所说所做",
  "用户最大的困扰、阻碍、担心": "用户最大的困扰、阻碍、担心",
  "用户真正想要/需要的东西": "用户真正想要/需要的东西",
  "服务成功的标准": "服务成功的标准",
  "生成用户之声": "生成用户之声",
  "查看用户之声": "查看用户之声",
  "隐藏用户之声": "隐藏用户之声",
  "用户之声": "用户之声",
  "权重": "权重",
  "当前在线": "当前在线",
  "你": "你",
  "编辑节点": "编辑节点",
  "字体大小": "字体大小",
  "字体颜色": "字体颜色",
  "节点背景": "节点背景",
  "边框颜色": "边框颜色",
  "编辑连线": "编辑连线",
  "标签内容": "标签内容",
  "线条样式": "线条样式",
  "线条宽度": "线条宽度",
  "流动线条": "流动线条",
  "调整线条": "调整线条",
  "背景颜色": "背景颜色",
  "描边颜色": "描边颜色",
  "10%": "10%",
  "50%": "50%",
  "100%": "100%",
  "150%": "150%",
  "200%": "200%",
  "适应屏幕": "适应屏幕",
  "删除此节点": "删除此节点",
  "创建项目失败，请稍后刷新页面重试": "创建项目失败，请稍后刷新页面重试",
  "请求项目数据失败，请稍后刷新页面重试": "请求项目数据失败，请稍后刷新页面重试",
  "项目名称限制30个字符，1个汉字占两个字符":  "项目名称限制30个字符，1个汉字占两个字符",
  "用户名称限制12个字符，1个汉字占两个字符":  "用户名称限制12个字符，1个汉字占两个字符",
  "用户名称保存成功": "用户名称保存成功",
  "项目名称保存成功": "项目名称保存成功",
  "保存成功": "保存成功",
  "内容不为空不可删除，请清空泳道后再删除": "内容不为空不可删除，请清空泳道后再删除",
  "只能输入0-10的数字": "只能输入0-10的数字",
  "复制成功": "复制成功",
  "请先添加痛点和需要": "请先添加痛点和需要",
  "点击进行编辑": "点击进行编辑",
  "导出共情图为图片": "导出共情图为图片",
  "导出的图片中包含用户之声": "导出的图片中包含用户之声",
  "确定": "确定",
  "请先去生成用户之声": "请先去生成用户之声",
  "邮箱": "邮箱",
  "密码": "密码",
  "注册": "注册",
  "忘记密码": "忘记密码",
  "登录": "登录",
  "注册账号": "注册账号",
  "名字/昵称": "名字/昵称",
  "确认": "确认",
  "返回登录": "返回登录",
  "输入6位的邮箱验证码": "输入6位的邮箱验证码",
  "已发送验证码到": "已发送验证码到",
  "，请登录邮箱查看": "，请登录邮箱查看",
  "邮箱验证": "邮箱验证",
  "注册成功": "注册成功",
  "去登录": "去登录",
  "不小于8位的字母数字组合": "不小于8位的字母数字组合",
  "密码必须是不少于8位的字母数字组合": "密码必须是不少于8位的字母数字组合",
  "邮箱格式不正确": "邮箱格式不正确",
  "邮箱已存在": "邮箱已存在",
  "邮箱未注册": "邮箱未注册",
  "两次输入的密码不一致": "两次输入的密码不一致",
  "新密码": "新密码",
  "再次输入": "再次输入",
  "密码已重置": "密码已重置",
  "重置密码": "重置密码",
  "验证码输入有误": "验证码输入有误",
  "验证码错误": "验证码错误",
  "名字或昵称仅中文、字母和数字，长度不超过20个字符": "名字或昵称仅中文、字母和数字，长度不超过20个字符",
  "返回": "返回",
  "您不是该项目的成员，没有访问权限": "您不是该项目的成员，没有访问权限",
  "当前项目": "当前项目",
  "加入项目": "加入项目",
  "创建项目": "创建项目",
  "快捷入口": "快捷入口",
  "邀请成员": "邀请成员",
  "最近更新": "最近更新",
  "分享邀请码，用户加入项目-输入邀请码即可": "分享邀请码，用户加入项目-输入邀请码即可",
  "复制邀请码": "复制邀请码",
  "复制链接": "复制链接",
  "邀请码已复制": "邀请码已复制",
  "邀请链接已复制": "邀请链接已复制",
  "输入邀请码加入项目": "输入邀请码加入项目",
  "请输入邀请码": "请输入邀请码",
  "分享邀请链接，访问链接即可加入": "分享邀请链接，访问链接即可加入",
  "邀请码不存在": "邀请码不存在",
  "取消": "取消",
  "加入项目成功": "加入项目成功",
  "后自动进入项目1": "",
  "后自动进入项目": "后自动进入项目",
  "成员管理": "成员管理",
  "当前项目成员": "当前项目成员",
  "移交组长权限": "移交组长权限",
  "成员": "成员",
  "项目权限": "项目权限",
  "组长": "组长",
  "全部权限": "全部权限",
  "查看、小组讨论": "查看、小组讨论",
  "删除项目": "删除项目",
  "服务画布": "服务画布",
  "邀请成员加入": "邀请成员加入",
  "项目名称": "项目名称",
  "确认创建": "确认创建",
  "暂不进入": "暂不进入",
  "进入项目": "进入项目",
  "项目删除成功": "项目删除成功",
  "分钟前": "分钟前",
  "小时前": "小时前",
  "天前": "天前",
  "项目ID": "项目ID",
  "已设置为组长": "已设置",
  "为组长": "为组长",
  "组长权限已移交，你已无该页面访问权限": "组长权限已移交，你已无该页面访问权限",
  "成员删除成功": "成员删除成功",
  "组长不可删除": "组长不可删除",
  "现在请每位同学独立思考，完成共情图，稍后将开始小组讨论。": "现在请每位同学独立思考，完成共情图，稍后将开始小组讨论。",
  "现在请每位同学独立思考，完成服务画布，稍后将开始小组讨论。": "现在请每位同学独立思考，完成服务画布，稍后将开始小组讨论。",
  "现在开始小组讨论，每个组员都可以看到其它同学的想法啦～": "现在开始小组讨论，每个组员都可以看到其它同学的想法啦～",
  "确定删除该项目？": "确定删除该项目？",
  "确定删除该成员？": "确定删除该成员？",
  "数据请求失败，请重试": "数据请求失败，请重试",
  "你已经加入该项目": "你已经加入该项目",
  "赛博物理系统设计": "赛博物理系统设计",
  "你好！": "你好！",
  "欢迎登录赛博物理系统设计": "欢迎登录赛博物理系统设计",
  "请各位同学独立思考完成服务画布，稍后进行小组讨论": "请各位同学独立思考完成服务画布，稍后进行小组讨论",
  "请各位同学独立思考完成共情图，稍后进行小组讨论": "请各位同学独立思考完成共情图，稍后进行小组讨论",
  "小组讨论": "小组讨论",
  "确认终版服务画布": "确认终版服务画布",
  "终版已确认": "终版已确认",
  "修改终版": "修改终版",
  "组员独立思考": "组员独立思考",
  "现在开始小组讨论": "现在开始小组讨论",
  "进入小组讨论": "进入小组讨论",
  "确认终版": "确认终版",
  "独立思考": "独立思考",
  "提交": "提交",
  "多人小组共创画布": "多人小组共创画布",
  "仅组长有控制权限": "仅组长有控制权限",
  "组员独立思考2": "组员独立思考",
  "小组讨论2": "小组讨论",
  "确认终版共情图": "确认终版共情图",
  "确认终版服务画布": "确认终版服务画布",
  "进入小组讨论2": "进入小组讨论",
  "退出当前账号": "退出当前账号",
  "当前还没有项目，请点击右上角按钮去创建项目吧": "当前还没有项目，请点击右上角按钮去创建项目吧",
  "注册失败": "注册失败",
  "账号密码不匹配": "账号密码不匹配",
  "暂未开放": "暂未开放",
  "更新数据": "更新数据",
  "更新后，当前表格内的权重将被清空，用户之声显示当前画布上的最新数据，确认继续吗？": "更新后，当前表格内的权重将被清空，用户之声显示当前画布上的最新数据，确认继续吗？"
}